<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="center">
      <v-col cols="11" xs="11" sm="11" md="8" lg="6" xl="5" class="mt-8">
        <v-text-field
          class="searchText"
          background-color="primaryCard"
          dark
          solo
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          :hint="this.hint"
          :error-messages="this.errorMessage"
          v-model="search"
          :disabled="working"
        ></v-text-field>
        <v-row justify="space-around"
          ><v-col cols="12" xs="11" sm="11" md="7" lg="5" xl="4"
            ><v-radio-group
              dark
              row
              mandatory
              v-model="searchOption"
              @change="setHint"
              :disabled="working"
            >
              <v-radio label="Mobile" value="mobile"></v-radio>
              <v-radio
                label="Name"
                value="name"
              ></v-radio> </v-radio-group></v-col
          ><v-col cols="12" xs="11" sm="11" md="4" lg="5" xl="4"
            ><v-btn
              dark
              large
              block
              color="primary"
              :loading="working"
              @click="searchValidate"
              >Search</v-btn
            ></v-col
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="7">
        <v-data-table
          dark
          :headers="headers"
          :items="users"
          :page.sync="page"
          :loading="tableLoading"
          item-key="mobile"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
          @click:row="edit"
          ><template v-slot:[`item.profile`]="{ item }">
            <v-avatar class="ma-2">
              <img :src="item.profile" :alt="item.name" />
            </v-avatar> </template
          ><template v-slot:[`item.name`]="{ item }">
            <div :class="nameColor(item.name)">
              {{ item.name }}
            </div> </template
          ><template v-slot:[`item.disabled`]="{ item }">
            <div :class="disabledColor(item.disabled)">
              {{ diabledText(item.disabled) }}
            </div> </template
          ><template v-slot:footer>
            <v-row justify="center" align="center">
              <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="6"
                ></v-pagination>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog dark v-model="editDialog" max-width="600px" :persistent="working">
      <v-card color="primaryCard">
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row justify="center">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-avatar size="200">
                    <v-img :src="profile"> </v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-switch
                    class="ml-3"
                    color="red"
                    v-model="disabled"
                    inset
                    :label="
                      this.disabled ? 'Account DISABLED' : 'Account Active'
                    "
                    :prepend-icon="this.disabled ? 'mdi-account-off' : 'mdi-account-check'"
                  ></v-switch
                ></v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    label="Mobile"
                    v-model="mobile"
                    disabled
                  ></v-text-field> </v-col
                ><v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field label="Name" v-model="name"></v-text-field>
                </v-col> </v-row
            ></v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="viewUser" :loading="working"> View Profile </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="update" :loading="working"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseStorage } from "@/main";
import { EventBus } from "@/main";
import moment from "moment";
import NavBar from "@/components/navbar";

export default {
  data: () => ({
    valid: true,
    loading: true,
    working: false,
    //search
    search: "",
    search_options: ["Mobile", "Name"],
    searchOption: null,
    hint: "",
    errorMessage: "",
    //page
    users: [],
    headers: [
      {
        text: "Mobile",
        align: "start",
        value: "mobile",
      },
      { text: "Avatar", value: "profile" },
      { text: "Name", value: "name" },
      { text: "Joined", value: "joined" },
      { text: "Disabled", value: "disabled" },
    ],
    tableLoading: true,
    page: 1,
    pageCount: 0,
    lastDoc: null,
    docRef: null,
    noMore: false,
    //Edit
    editDialog: false,
    uid: "",
    name: "",
    mobile: "",
    profile: "",
    disabled: true,
  }),
  components: {
    NavBar,
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getusers();
  },
  watch: {
    page: {
      handler() {
        if (this.users.length != 0) {
          if (this.page >= this.pageCount - 5 && this.noMore == false) {
            this.getusers();
          }
        }
      },
    },
  },
  computed: {},
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    setHint() {
      if (this.searchOption == "mobile") {
        this.hint = "Enter full/part of the mobile number";
        this.search = "+94";
      } else {
        this.hint = "Enter full/part of a name";
        this.search = "";
      }
    },
    searchValidate() {
      this.working = true;

      if (this.searchOption == "mobile") {
        if (this.search.length != 0) {
          this.readySearch();
        } else {
          this.errorMessage = "Mobile number cannot be empty";
          this.working = false;
        }
      } else {
        if (this.search.length != 0) {
          this.readySearch();
        } else {
          this.errorMessage = "Name field cannot be empty";
          this.working = false;
        }
      }
    },
    readySearch() {
      this.users = [];
      this.page = 1;
      this.pageCount = 0;
      this.lastDoc = null;
      this.docRef = null;
      this.noMore = false;
      this.tableLoading = true;
      this.loading = true;
      this.emitLoadingState();
      this.getSearch();
    },
    getSearch() {
      if (this.lastDoc == null) {
        this.docRef = firebaseFirestore
          .collection("users")
          .where(this.searchOption, ">=", this.search)
          .where(this.searchOption, "<=", this.search + "\uf8ff")
          .limit(30);
      } else {
        this.docRef = firebaseFirestore
          .collection("users")
          .where(this.searchOption, ">=", this.search)
          .where(this.searchOption, "<=", this.search + "\uf8ff")
          .startAfter(this.lastDoc)
          .limit(30);
      }
      this.pushToTable();
    },
    getusers() {
      if (this.lastDoc == null) {
        this.docRef = firebaseFirestore
          .collection("users")
          .orderBy("created", "desc")
          .limit(30);
      } else {
        this.docRef = firebaseFirestore
          .collection("users")
          .orderBy("created", "desc")
          .startAfter(this.lastDoc)
          .limit(30);
      }
      this.pushToTable();
    },
    pushToTable() {
      this.docRef
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            this.noMore = true;
          } else {
            this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

            for (let i = 0; i < querySnapshot.docs.length; i++) {
              const data = {
                joined: this.formatDate(
                  querySnapshot.docs[i].data().created.toDate(),
                  "DD-MMM-YYYY / hh:mm A"
                ),
                disabled: querySnapshot.docs[i].data().disabled,
                mobile: querySnapshot.docs[i].data().mobile,
                name: this.setName(querySnapshot.docs[i].data().name),
                profile: querySnapshot.docs[i].data().profile,
                uid: querySnapshot.docs[i].data().uid,
              };
              this.users.push(data);
            }
          }
        })
        .then(() => {
          this.loading = false;
          this.working = false;
          this.tableLoading = false;
          this.emitLoadingState();
          this.tableLoading = false;
        });
    },
    edit(item, row) {
      this.uid = item.uid;
      this.mobile = item.mobile;
      this.profile = item.profile;
      if (item.name != "Name not added yet") {
        this.name = item.name;
      }
      this.disabled = item.disabled;
      this.editDialog = true;
    },
    update() {
      this.working = true;
      firebaseFirestore
        .collection("users")
        .doc(this.uid)
        .set(
          {
            name: this.name,
            disabled: this.disabled,
          },
          { merge: true }
        )
        .then(() => {
          this.working = false;
          this.editDialog = false;
          this.uid = "";
          this.mobile = "";
          this.name = "";
          this.disabled = true;
        });

      this.users = [];
      this.page = 1;
      this.pageCount = 0;
      this.lastDoc = null;
      this.docRef = null;
      this.noMore = false;
      this.tableLoading = true;
      this.loading = true;
      this.emitLoadingState();
      this.getusers();
    },
    formatDate(timeStamp, format) {
      var m = moment(timeStamp);
      var mFormatted = m.format(format);
      return mFormatted;
    },
    setName(name) {
      if (name != "") {
        return name;
      } else {
        return "Name not added yet";
      }
    },
    nameColor(name) {
      if (name == "Name not added yet") {
        return "grey--text text--darken-1";
      } else {
        return name;
      }
    },
    disabledColor(state) {
      if (state == true) {
        return "red--text";
      } else {
        return "green--text";
      }
    },
    diabledText(state) {
      if (state == true) {
        return "Disabled";
      } else {
        return "Enabled";
      }
    },
    viewUser() {
      if (this.uid != "") {
        window.location.href = "/viewUser/" + this.uid;
      }
    },
  },
};
</script>

<style scoped>
.searchText {
  font-size: 1.2em;
  transform: scale(1.1);
}
</style>
