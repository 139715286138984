import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#000000',
            secondary: '#041d74',
            accent: '#FF6D00',
            error: '#b71c1c',
            nav: '#000000',
            fill: '#E8EAF6',
            background: '#212121',
            drawer: '#000000',
            primaryText: '#BDBDBD',
            primaryButton: '#757575',
            primaryCard: '#000000',
            secondaryCard: '#BDBDBD',
          },
          dark: {
            primary: '#607D8B',
            secondary: '#90A4AE',
            accent: '#304FFE',
            nav: '#000000',
            fill: '#212121',
            background: '#212121',
            drawer: '#000000',
            primaryText: '#BDBDBD',
            primaryButton: '#757575',
            primaryCard: '#000000',
            secondaryCard: '#BDBDBD',
          },
        },
      },
});
