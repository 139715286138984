<template>
  <div>
    <v-toolbar color="nav" class="elevation-0">
      <v-app-bar-nav-icon @click="emitDrawerState" dark></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-progress-linear
        :active="loading"
        indeterminate
        absolute
        bottom
        height="3"
        color="primaryButton"
      ></v-progress-linear>
    </v-toolbar>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import { firebaseAuth } from "@/main";

export default {
    data() {
    return {
      title: "",
      loading: true,
      working: false,
      userEmail: "",
    };
  },
  watch: {
    $route() {
      this.updateTitle();
    },
  },
  mounted() {
    this.updateTitle();
  },
  created() {
    const self = this;
    this.userEmail = localStorage.email;
    EventBus.$on("loadingState", (payload) => {
      self.loading = payload;
    });
  },
  methods: {
    updateTitle() {
      const self = this;
      self.title = self.$route.meta.title;
    },
    emitDrawerState() {
      EventBus.$emit("drawerState");
    },
  },
}
</script>

<style>

</style>