<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="end">
      <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
        <v-btn color="primary" class="white--text mt-6" @click="openDialog(null)">
          <v-icon left> mdi-plus </v-icon>
          Add Notice
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="4">
        <template v-for="banner in banners">
          <v-list-item :key="banner.docID">
            <v-card
              width="100%"
              :elevation="1"
              :color="banner.color"
              class="rounded-card my-2"
            >
            <v-card-title class="white--text">
                Position - {{banner.order}}
            </v-card-title>
              <v-card-text>
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="white--text text-h6 ma-3 noticeText">
                      {{ banner.text }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="loading"
                  @click="deleteBanner(banner.docID)"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="noticeDialog" max-width="600" :persistent="working">
      <v-card dark>
        <v-card-title>{{ this.noticeTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            filled
            label="Notice text"
            auto-grow
            v-model="noticeText"
            :background-color="noticeColor"
            counter="150"
            class="white--text"
          ></v-textarea>
          <v-overflow-btn
            v-model="noticeColor"
            class="my-2"
            :items="this.colors"
            label="Background Color"
            filled
            dark
          ></v-overflow-btn>
          <v-row justify="center" align="center">
            <v-col cols="4" xs="4" sm="4" md="4" lg="3" xl="2">
              <span class="text-h2 font-weight-light" v-text="noticeOrder"></span>
              <span class="subheading font-weight-light mr-1"> in position</span>
            </v-col>
            <v-col cols="7" xs="7" sm="7" md="7" lg="8" xl="9">
              <v-slider
                v-model="noticeOrder"
                color="white"
                track-color="grey"
                always-dirty
                min="0"
                max="10"
              >
                <template v-slot:prepend>
                  <v-icon color="white" @click="decrement"> mdi-minus </v-icon>
                </template>

                <template v-slot:append>
                  <v-icon color="white" @click="increment"> mdi-plus </v-icon>
                </template>
              </v-slider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="working"
            :disabled="creatable"
            block
            class="mb-5"
            @click="createNotice"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { firebaseFirestore } from "@/main";
import NavBar from "@/components/navbar";
import { EventBus } from "@/main";

export default {
  data: () => ({
    loading: true,
    working: false,
    banners: [],
    colors: [
      "purple darken-4",
      "pink darken-4",
      "indigo darken-4",
      "teal darken-4",
      "green darken-4",
      "orange darken-4",
    ],
    noticeDialog: false,
    noticeTitle: "",
    noticeText: "",
    noticeColor: "",
    noticeOrder: 0,
  }),
  components: {
    NavBar,
  },
  computed: {
    creatable() {
      if (this.noticeText.length >= 151) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getNotices();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getNotices() {
      firebaseFirestore
        .collection("banner")
        .orderBy("order")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              text: doc.data().text,
              color: doc.data().color,
              order: doc.data().order,
              docID: doc.id,
            };
            this.banners.push(data);
          });
        })
        .then(() => {
          this.loading = false;
          this.emitLoadingState();
        });
    },
    deleteBanner(docID) {
      this.loading = true;
      this.emitLoadingState();

      firebaseFirestore
        .collection("banner")
        .doc(docID)
        .delete()
        .then(() => {
          this.banners = [];
          this.getNotices();
        });
    },
    openDialog(banner) {
      if (banner == null) {
        this.noticeTitle = "Add new notice";
        this.noticeText = "";
        this.noticeColor = this.colors[0];
        this.noticeOrder = 0;
        this.noticeDialog = true;
      }
    },
    decrement() {
      this.noticeOrder--;
    },
    increment() {
      this.noticeOrder++;
    },
    createNotice() {
      this.working = true;
      firebaseFirestore
        .collection("banner")
        .add({
          text: this.noticeText,
          color: this.noticeColor,
          order: this.noticeOrder,
        })
        .then(() => {
          this.working = false;
          this.noticeDialog = false;
          this.banners = [];
          this.getNotices();
        });
    },
  },
};
</script>

<style></style>
