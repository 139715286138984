<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-sheet height="64" dark>
          <v-toolbar flat>
            <v-btn outlined class="mr-4" dark @click="setToday"> Today </v-btn>
            <v-btn fab text small dark @click="$refs.calendar.prev()">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small dark @click="$refs.calendar.next()">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="!isMobile">
              <v-btn tile outlined class="mr-4" v-if="type == 'day' && dateButtons" :disabled="working"
                @click="showSlotsDialog()">
                <v-icon left> mdi-block-helper </v-icon>
                Block Slot
              </v-btn>
              <v-btn tile outlined class="mr-4" v-if="type == 'day' && dateButtons" :loading="working"
                @click="closeDate()">
                <v-icon left> mdi-store-off </v-icon>
                Close Salon
              </v-btn>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined dark v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list dark>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-toolbar>
        </v-sheet>
        <v-row v-if="isMobile" justify="center">
          <v-col>
            <v-btn tile dark outlined class="ma-4" v-if="type == 'day' && dateButtons" :disabled="working"
              @click="showSlotsDialog()">
              <v-icon left> mdi-block-helper </v-icon>
              Block Slot
            </v-btn>
            <v-btn tile dark outlined class="ma-4" v-if="type == 'day' && dateButtons" :loading="working"
              @click="closeDate()">
              <v-icon left> mdi-store-off </v-icon>
              Close Salon
            </v-btn>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined dark v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-sheet :height="calenderHeight">
          <v-calendar dark ref="calendar" v-model="focus" color="primary" :events="events" :type="type"
            :weekdays="weekday" @change="calenderChange" @click:event="showEvent" @click:more="viewDay"
            @click:date="viewDay"></v-calendar>
          <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x
            max-width="500">
            <v-card min-width="350px" flat dark>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.slot"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="openAppointment(selectedEvent)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon v-if="selectedEvent.state == 1" @click="viewUser(selectedEvent.userID)">
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
                <v-btn icon v-if="selectedEvent.state == 1 && selectedEvent.past == false"
                  @click="showDeleteConfirmation(selectedEvent)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn tile v-if="selectedEvent.state == 2 && selectedEvent.past == false"
                  @click="enableAppointment(selectedEvent)" :loading="working"><v-icon
                    left>mdi-power-settings</v-icon>Enable</v-btn>
                <v-btn tile v-if="selectedEvent.state == 3 && selectedEvent.past == false"
                  @click="openDate(selectedEvent)" :loading="working"><v-icon left>mdi-store-check</v-icon>Open</v-btn>
              </v-toolbar>
              <v-card-text v-if="selectedEvent.state == 1">
                <div class="cardText">Name : {{ selectedEvent.name }}</div>
                <div class="cardText">Mobile : {{ selectedEvent.mobile }}</div>
                <div class="cardText">Technician : {{ selectedEvent.staff }}</div>
                <div class="cardText">
                  Booked : {{ selectedEvent.timestamp }}
                </div>
              </v-card-text>
              <v-card-text v-if="selectedEvent.state == 2">
                <div class="cardText">Slot : {{ selectedEvent.name }}</div>
              </v-card-text>
              <v-card-text v-if="selectedEvent.state == 3">
                <p v-if="selectedEvent.past == false">
                  Click open to make this date available for bookings
                </p>
                <p v-if="selectedEvent.past == true">
                  The salon was closed on this day
                </p>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteConfirmationDialog" max-width="400" :persistent="working">
      <v-card dark>
        <v-card-title> Delete Appointment? </v-card-title>
        <v-card-text class="text-subtitle-1">
          Do you really want to delete this appointment?
          <p></p>
          <v-checkbox v-model="disableSlot" label="Disable slot as well" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red darken-4" :disabled="working" @click="deleteConfirmationDialog = false">
            No
          </v-btn>
          <v-btn text color="green darken-4" :loading="working" @click="deleteAppointment()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="slotDialog" max-width="600">
      <v-card dark>
        <v-card-title primary-title> Disable Slots </v-card-title>
        <div class="centerContent">
          <v-progress-circular v-if="working" :size="70" :width="7" color="grey" indeterminate
            class="my-10"></v-progress-circular>
        </div>

        <v-card-text v-if="!working">
          <v-container fluid>
            <v-textarea class="mb-10" counter label="Enter reason if any" :rules="reasonRules"
              v-model="reason"></v-textarea>
            <v-layout row wrap>
              <v-flex v-for="slot in slots" :key="slot.slot" xs10 lg6 class="centerContent">
                <template>
                  <v-btn v-if="slot.disabled" disabled block class="ma-1">{{
              slot.slot
            }}</v-btn>
                  <v-btn v-if="slot.booked && !slot.disabled" color="green darken-4" block elevation="0" class="ma-1"
                    @click="snackbar = true">{{ slot.slot }}</v-btn>
                  <v-btn v-if="!slot.booked && !slot.disabled" color="white" outlined block class="ma-1"
                    @click="disableAppointment(slot.date, slot.slot)">{{ slot.slot }}</v-btn>
                </template>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="5000">
      Go to calender and click on appointment to cancel booking and disabled
      slot.
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
import moment from "moment";
import NavBar from "@/components/navbar";

export default {
  data: () => ({
    loading: true,
    working: false,
    //calender
    calenderHeight: 0,
    monthStartDate: null,
    monthEndDate: null,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    colors: [
      "blue darken-4",
      "indigo darken-4",
      "purple darken-4",
      "deep-purple darken-4",
      "cyan darken-4",
      "teal darken-4",
      "green darken-4",
    ],
    //data for calender
    count: 0,
    dates: [],
    events: [],
    timestampNow: new Date(),
    pastDate: false,
    //menu
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    //event delete
    deleteConfirmationDialog: false,
    disableSlot: false,
    deleteEvent: null,
    //
    dateButtons: false,
    slotDialog: false,
    slots: [],
    //Reason
    reasonRules: [(v) => v.length <= 100 || "Max 100 characters"],
    reason: "",
    //
    snackbar: false,
  }),
  components: {
    NavBar,
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return null;
    },
  },
  beforeMount() {
    this.calenderHeight = (window.innerHeight / 100) * 85;
    window.addEventListener("resize", () => {
      this.calenderHeight = (window.innerHeight / 100) * 85;
    });
  },
  async created() {
    this.loading = true;
    this.emitLoadingState();
    var getDate = firebaseFunctions.httpsCallable("getDate");

    await getDate({ format: "" }).then((result) => {
      this.timestampNow = new Date(result.data.formated);
    });
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    calenderChange({ start, end }) {
      this.monthStartDate = new Date(`${start.date}T00:00:00`);
      this.monthEndDate = new Date(`${end.date}T23:59:59`);
      this.getDates();
    },
    getDates() {
      this.loading = true;
      this.emitLoadingState();
      this.events = [];
      this.dates = [];

      if (this.type == "day") {
        const tempArray = this.focus.split("-");
        const slotDate = new Date(tempArray[0], tempArray[1] - 1, tempArray[2]);

        firebaseFirestore
          .collection("appointments")
          .doc(this.getDateFormatedToString(slotDate, "DD-MMM-YYYY"))
          .get()
          .then((returnedDoc) => {
            if (this.timestampNow > returnedDoc.data().timestamp.toDate()) {
              this.dateButtons = false;
            } else {
              if (returnedDoc.data().disabled == true) {
                this.dateButtons = false;
              } else {
                this.dateButtons = true;
              }
            }
          });
      }

      firebaseFirestore
        .collection("appointments")
        .where("timestamp", ">", this.monthStartDate)
        .where("timestamp", "<", this.monthEndDate)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.loading = false;
            this.emitLoadingState();
          } else {
            querySnapshot.forEach((doc) => {
              this.dates.push({
                date: this.getDateFormatedToString(
                  doc.data().timestamp.toDate(),
                  "DD-MMM-YYYY"
                ),
                disabled: doc.data().disabled,
                timestamp: doc.data().timestamp,
              });
            });
            this.getAppointments();
          }
        })
        .catch((error) => {
          console.log("Error --- " + error);
        });
    },
    getAppointments() {
      this.count = 0;

      for (let i = 0; i < this.dates.length; i++) {
        this.count++;

        if (this.dates[i].disabled == true) {
          if (this.timestampNow > this.dates[i].timestamp.toDate()) {
            this.pastDate = true;
          } else {
            this.pastDate = false;
          }
          this.events.push({
            name: "CLOSED",
            slot: "Salon Closed",
            start: this.dates[i].timestamp.toDate(),
            color: "primary",
            date: this.getDateFormatedToString(
              this.dates[i].timestamp.toDate(),
              "DD-MMM-YYYY"
            ),
            past: this.pastDate,
            state: 3,
          });
        } else if (this.dates[i].disabled == false) {
          firebaseFirestore
            .collection("appointments")
            .doc(this.dates[i].date.toString())
            .collection("slots")
            .get()
            .then(async (querySnapshot) => {
              for (const doc of querySnapshot.docs) {
                if (this.timestampNow > doc.data().start.toDate()) {
                  this.pastDate = true;
                } else {
                  this.pastDate = false;
                }
                if (doc.data().booked == true) {
                  const userDetails = await this.getUserDetails(
                    doc.data().userID
                  );
                  this.events.push({
                    docRef: doc.data().docRef,
                    userID: doc.data().userID,
                    staff: this.processStaff(doc.data().staff),
                    name: userDetails.name,
                    mobile: userDetails.mobile,
                    slot: doc.data().slot,
                    timestamp: this.getDateFormatedToString(
                      doc.data().timestamp.toDate(),
                      "DD-MMM-YYYY  hh:mm A"
                    ),
                    date: this.getDateFormatedToString(
                      doc.data().start.toDate(),
                      "DD-MMM-YYYY"
                    ),
                    start: doc.data().start.toDate(),
                    end: doc.data().end.toDate(),
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    disabled: doc.data().disabled,
                    past: this.pastDate,
                    timed: true,
                    state: 1,
                  });
                } else if (doc.data().disabled == true) {
                  this.events.push({
                    name: "SLOT BLOCKED " + doc.data().reason,
                    slot: doc.data().slot,
                    disabled: doc.data().disabled,
                    date: this.getDateFormatedToString(
                      doc.data().start.toDate(),
                      "DD-MMM-YYYY"
                    ),
                    start: doc.data().start.toDate(),
                    end: doc.data().end.toDate(),
                    color: "red darken-4",
                    past: this.pastDate,
                    timed: true,
                    state: 2,
                  });
                }
              }
            });
        }
        if (this.count == this.dates.length) {
          this.loading = false;
          this.emitLoadingState();
        }
      }
    },
    async getUserDetails(userID) {
      if (!userID) {
        return { name: "Error getting user details", mobile: "", profile: "" };
      }

      const docPath = firebaseFirestore.collection("users").doc(userID);
      try {
        const docSnap = await docPath.get();
        return { name: docSnap.data().name, mobile: docSnap.data().mobile, profile: docSnap.data().profile, };
      } catch (e) {
        console.log("Error getting user details ", e);
      }
    },
    getDateFormatedToString(date, format) {
      var momentObj = moment(date);
      var momentString = momentObj.format(format);
      return momentString;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    showDeleteConfirmation(event) {
      this.deleteEvent = event;
      this.disableSlot = event.disabled;
      this.deleteConfirmationDialog = true;
    },
    deleteAppointment() {
      this.working = true;

      var cancelAppointmentAdmin = firebaseFunctions.httpsCallable(
        "cancelAppointmentAdmin"
      );

      cancelAppointmentAdmin({
        userID: this.deleteEvent.userID,
        docID: this.deleteEvent.docRef,
        disabled: this.disableSlot,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.deleteConfirmationDialog = false;
          this.working = false;
          this.$router.go();
        } else if (result.data.response == "Error") {
          console.log("Error - Report to Developer");
        }
      });
    },
    enableAppointment(event) {
      this.working = true;

      var enableSlot = firebaseFunctions.httpsCallable("enableSlot");

      enableSlot({
        dateString: event.date,
        slotString: event.slot,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.$router.go();
        } else if (result.data.response == "Error") {
          console.log("Error - Report to Developer");
        }
      });
    },
    openDate(event) {
      this.working = true;

      var openShop = firebaseFunctions.httpsCallable("openShop");

      openShop({
        dateString: event.date,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.$router.go();
        } else if (result.data.response == "Error") {
          console.log("Error - Report to Developer");
        }
      });
    },
    async showSlotsDialog() {
      this.slots = [];
      this.working = true;
      this.slotDialog = true;
      const tempArray = this.focus.split("-");
      const slotDate = new Date(tempArray[0], tempArray[1] - 1, tempArray[2]);

      await firebaseFirestore
        .collection("appointments")
        .doc(this.getDateFormatedToString(slotDate, "DD-MMM-YYYY"))
        .collection("slots")
        .get()
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.slots.push({
              date: this.getDateFormatedToString(slotDate, "DD-MMM-YYYY"),
              slot: doc.data().slot,
              booked: doc.data().booked,
              disabled: doc.data().disabled,
            });
          });
        })
        .then(() => {
          this.working = false;
        });
    },
    disableAppointment(dateString, slotString) {
      this.working = true;

      var disableSlot = firebaseFunctions.httpsCallable("disableSlot");

      disableSlot({
        dateString: dateString,
        slotString: slotString,
        reason: this.reason,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.showSlotsDialog();
          this.reason = "";
          this.getDates();
        } else if (result.data.response == "Error") {
          this.snackbar = true;
          this.working = false;
          this.showSlotsDialog();
          this.getDates();
        }
      });
    },
    closeDate(dateString) {
      this.working = true;
      const tempArray = this.focus.split("-");
      const slotDate = new Date(tempArray[0], tempArray[1] - 1, tempArray[2]);

      var closeShop = firebaseFunctions.httpsCallable("closeShop");

      closeShop({
        dateString: this.getDateFormatedToString(slotDate, "DD-MMM-YYYY"),
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.$router.go();
        } else if (result.data.response == "Error") {
          console.log("Error - Report to Developer");
        }
      });
    },
    viewUser(userID) {
      if (userID != "") {
        window.location.href = "/viewUser/" + userID;
      }
    },
    openAppointment(selectedEvent) {
      window.location.href = "/appointments/" + selectedEvent.date + "/" + selectedEvent.slot;
    },
    processStaff(name){
      if (name == undefined){
        return "ANY"
      } else {
        return name;
      }
    }
  },
};
</script>

<style scoped>
.cardText {
  font-size: 1.3em;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
