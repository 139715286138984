<template>
  <div>
    <v-navigation-drawer v-model="drawer" app bottom color="drawer" class="elevation-4">
      <v-list>

        <v-list-item link href="/">
          <v-list-item-icon>
            <v-icon dark>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/appointments">
          <v-list-item-icon>
            <v-icon dark>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">Appointments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/appUsers">
          <v-list-item-icon>
            <v-icon dark>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">App Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/verifyUser">
          <v-list-item-icon>
            <v-icon dark>mdi-account-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">Verification</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/banner">
          <v-list-item-icon>
            <v-icon dark>mdi-bullhorn</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">Notice Banners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/sms">
          <v-list-item-icon>
            <v-icon dark>mdi-message</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">SMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
      <template v-slot:append>
        <div class="pa-2">
        <v-btn block large dark @click="logout" color="primaryButton"> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseAuth } from "@/main";
import { EventBus } from "@/main";

export default {
  data() {
    return {
      drawer: false,
      userEmail: "",
      userRole: "",
      lastLogin: "",
      moderator: true,
      theme: "dark",
    };
  },
  created() {
    this.userEmail = localStorage.email;
    this.userRole = localStorage.role;
    this.lastLogin = firebaseAuth.currentUser.metadata.lastSignInTime;

    const self = this;
    EventBus.$on("drawerState", (payload) => {
      self.drawer = !self.drawer;
    });

    if (localStorage.theme == "dark") {
      this.$vuetify.theme.dark = true;
      this.theme = "light";
    } else if (localStorage.theme == "light") {
      this.$vuetify.theme.dark = false;
      this.theme = "dark";
    }

    if (localStorage.role == "moderator") {
      this.moderator = true;
    } else {
      this.moderator = false;
    }
  },
  computed: {
    navStatus() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return true;
      }
      return null;
    },
  },
  methods: {
    changeTheme() {
      if (this.$vuetify.theme.dark == false) {
        this.$vuetify.theme.dark = true;
        localStorage.theme = "dark";
        this.theme = "light";
      } else {
        this.$vuetify.theme.dark = false;
        localStorage.theme = "light";
        this.theme = "dark";
      }
    },
    logout: function () {
      firebaseAuth.signOut().then(() => {
        localStorage.clear();
        this.$router.go({ path: this.$router.path });
      });
    },
  },
};
</script>

<style></style>
