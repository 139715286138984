<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="end">
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <v-btn color="primary" class="white--text mt-6">
          <v-icon left> mdi-message-text-fast </v-icon>
          Send SMS
        </v-btn>
        <v-btn
          color="primary"
          class="white--text mt-6 ml-2"
          href="https://app.notify.lk/api/v1/status?user_id=15864&api_key=jT7yJxHRRKaLiTQDGHTu"
          target="_blank"
        >
          <v-icon left> mdi-currency-usd </v-icon>
          Check Balance
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-data-table
          dark
          :headers="headers"
          :items="history"
          :page.sync="page"
          :loading="tableLoading"
          item-key="timestamp"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
          ><template v-slot:[`item.status`]="{ item }">
            <div :class="statusColor(item.status)">
              {{ item.status }}
            </div> </template
          ><template v-slot:footer>
            <v-row justify="center" align="center">
              <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="6"
                ></v-pagination>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions, firebaseStorage } from "@/main";
import NavBar from "@/components/navbar";
import { EventBus } from "@/main";
import moment from "moment";

export default {
  data: () => ({
    loading: true,
    working: false,
    history: [],
    headers: [
      {
        text: "Date & Time",
        align: "start",
        value: "timestamp",
      },
      { text: "Mobile", value: "mobile" },
      { text: "Message", value: "message" },
      { text: "Status", value: "status" },
    ],
    tableLoading: true,
    totalItems: 10,
    page: 1,
    pageCount: 0,
    lastDoc: null,
    docRef: null,
    noMore: false,
  }),
  components: {
    NavBar,
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getHistory();
  },
  watch: {
    page: {
      handler() {
        if (this.history.length != 0) {
          if (this.page >= this.pageCount - 5 && this.noMore == false) {
            this.getHistory();
          }
        }
      },
    },
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getHistory() {
      this.tableLoading = true;
      if (this.lastDoc == null) {
        this.docRef = firebaseFirestore
          .collection("sms-out")
          .orderBy("timestamp", "desc")
          .limit(30);
      } else {
        this.docRef = firebaseFirestore
          .collection("sms-out")
          .orderBy("timestamp", "desc")
          .startAfter(this.lastDoc)
          .limit(30);
      }

      this.docRef.get().then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          this.noMore = true;
        } else {
          this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

          for (let i = 0; i < querySnapshot.docs.length; i++) {
            const data = {
              message: querySnapshot.docs[i].data().message,
              mobile: querySnapshot.docs[i].data().mobile,
              status: querySnapshot.docs[i].data().status,
              timestamp: this.formatDate(
                querySnapshot.docs[i].data().timestamp.toDate(),
                "DD MMM YYYY --- hh:mm:ss A"
              ),
            };
            this.history.push(data);
          }

          this.tableLoading = false;
          this.loading = false;
          this.emitLoadingState();
        }
      });
    },
    formatDate(timeStamp, format) {
      var m = moment(timeStamp);
      var mFormatted = m.format(format);
      return mFormatted;
    },
    statusColor(state) {
      if (state == "sent") {
        return "green--text";
      } else if (state == "pending") {
        return "amber--text";
      } else {
        return "red--text";
      }
    },
  },
};
</script>

<style>
</style>