<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <v-card class="mt-10" dark color="primaryCard" :loading="loading">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img :src="this.profile"></v-img>
          <v-card-title class="mt-2"
            >{{ this.mobile }}<v-spacer />
            <v-chip v-if="this.disabled" class="red darken-4"
              >Account disabled</v-chip
            >
            <v-chip v-if="!this.disabled" class="green darken-4"
              >Account active</v-chip
            >
          </v-card-title>
          <v-card-text>
            <div class="mt-4 text-h5">{{ this.name }}</div>
            <div class="mt-4 text-subtitle-1">Joined on {{ this.created }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <div class="text-overline grey--text text--darken-2">
              Last login on {{ this.lastLogin }}
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="7"
        ><v-data-table
          dark
          :headers="headers"
          :items="appointments"
          :page.sync="page"
          :loading="tableLoading"
          item-key="timestamp"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.cancelled`]="{ item }">
            <div :class="cancelledColor(item.cancelled)">
              {{ item.cancelled }}
            </div>
          </template>

          <template v-slot:footer>
            <v-row justify="center" align="center">
              <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="6"
                ></v-pagination>
              </v-col> </v-row></template></v-data-table
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions } from "@/main";
import NavBar from "@/components/navbar";
import moment from "moment";
import { EventBus } from "@/main";

export default {
  data: () => ({
    loading: true,
    working: false,
    noData: false,
    name: "",
    mobile: "",
    profile: "",
    disabled: null,
    created: "",
    lastLogin: "",
    //apointments
    tableLoading: true,
    totalItems: 10,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: "Booked At",
        align: "start",
        value: "created",
      },
      { text: "Appointment Date", value: "dateString" },
      { text: "Time Slot", value: "slotString" },
      { text: "Status", value: "cancelled" },
      { text: "By", value: "cancelledBy" },
      { text: "At", value: "cancelledAt" },
    ],
    appointments: [],
    lastDoc: null,
    docRef: null,
  }),
  components: {
    NavBar,
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getUserDetails(this.$route.params.userID);
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getUserDetails(userID) {
      firebaseFirestore
        .collection("users")
        .doc(userID)
        .get()
        .then((returnedDoc) => {
          if (returnedDoc.exists) {
            this.name = returnedDoc.data().name;
            this.mobile = returnedDoc.data().mobile;
            this.profile = returnedDoc.data().profile;
            this.disabled = returnedDoc.data().disabled;
            this.created = this.getDateFormatedToString(
              returnedDoc.data().created.toDate(),
              "DD/MMM/YYYY - hh:mm:A"
            );
          } else {
            this.noData = true;
          }
        })
        .then(() => {
          firebaseFirestore
            .collection("users")
            .doc(userID)
            .collection("last")
            .doc("login")
            .get()
            .then((returnedDoc) => {
              if (returnedDoc.exists) {
                this.lastLogin = this.getDateFormatedToString(
                  returnedDoc.data().timestamp.toDate(),
                  "DD/MMM/YYYY - hh:mm:A"
                );
              }
              this.loading = false;
              this.emitLoadingState();
              this.getHistory(userID);
            });
        });
    },
    getHistory(userID) {
      this.tableLoading = true;

      if (this.lastDoc == null) {
        this.docRef = firebaseFirestore
          .collection("users")
          .doc(userID)
          .collection("appointments")
          .orderBy("timestamp", "desc")
          .limit(30);
      } else {
        this.docRef = firebaseFirestore
          .collection("users")
          .doc(userID)
          .collection("appointments")
          .orderBy("timestamp", "desc")
          .startAfter(this.lastDoc)
          .limit(30);
      }

      this.docRef.get().then((querySnapshot) => {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
          const data = {
            cancelled: this.cancelledText(doc.data().cancelled),
            cancelledBy: doc.data().cancelledBy,
            cancelledAt: this.cancelledTimeProcess(doc.data().cancelledAt),
            dateString: doc.data().dateString,
            slotString: doc.data().slotString,
            created: this.formatDate(doc.data().timestamp.toDate()),
            timestamp: doc.data().timestamp.toDate().toUTCString(),
          };
          this.appointments.push(data);
        });
        this.tableLoading = false;
      });
    },
    cancelledText(cancelled) {
      if (cancelled) {
        return "Cancelled";
      } else {
        return " Active ";
      }
    },
    cancelledTimeProcess(dataIn) {
      if (dataIn == "") {
        return "";
      } else {
        return this.formatDate(dataIn.toDate());
      }
    },
    cancelledColor(cancelled) {
      if (cancelled == "Cancelled") {
        return "error--text";
      } else {
        return "grey--text";
      }
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY HH:MM:SS");
      return mFormatted;
    },
    getDateFormatedToString(date, format) {
      var momentObj = moment(date);
      var momentString = momentObj.format(format);
      return momentString;
    },
  },
};
</script>

<style scoped>
</style>