<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
import moment from "moment";
import NavBar from "@/components/navbar";

export default {
  data: () => ({
    loading: true,
    working: false,
    booked: false,
    disabled: false,
    docRef: "",
    slot: "",
    userID: "",
    bookedOn: "",
  }),
  components: {
    NavBar,
  },
  created() {
    this.getAppointment();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getAppointment() {
      this.loading = true;
      this.emitLoadingState();

      firebaseFirestore
        .collection("appointments")
        .doc(this.$route.params.date)
        .collection("slots")
        .doc(this.$route.params.slot)
        .get()
        .then((returnedDoc) => {
          this.booked = returnedDoc.data().booked;
          this.disabled = returnedDoc.data().disabled;
          this.docRef = returnedDoc.data().docRef;
          this.slot = returnedDoc.data().slot;
          this.userID = returnedDoc.data().userID;
          this.bookedOn = returnedDoc.data().bookedOn;
        })
        .then(() => {
          this.loading = false;
          this.emitLoadingState();
        });
    },
  },
};
</script>

<style></style>
