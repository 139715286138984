<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="3">
        <v-card class="mt-10" dark>
          <v-list color="primaryCard">
            <v-list-item v-for="(request, i) in requests" :key="i">
              <v-avatar size="40" class="mr-5">
                <v-img
                  :src="request.profile"
                  lazy-src="https://firebasestorage.googleapis.com/v0/b/royal-hair-care.appspot.com/o/profile.jpg?alt=media&token=c4156c55-7064-421d-bf1d-910d94870210"
                >
                  <template v-slot:placeholder>
                    <v-row align="center" justify="center" class="mt-1">
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="request.mobile"></v-list-item-title>

                <v-list-item-subtitle
                  v-text="request.name"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-row>
                  <v-col
                    ><v-btn
                      small
                      v-if="!request.success"
                      :loading="request.working"
                      :disabled="working && !request.working"
                      color="background"
                      class="ma-2 white--text"
                      fab
                      @click="deleteRequest(i)"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                    </v-btn>
                    <v-btn
                      small
                      v-if="!request.success"
                      :loading="request.working"
                      :disabled="working && !request.working"
                      color="background"
                      class="ma-2 white--text"
                      fab
                      @click="verifyUser(i)"
                    >
                      <v-icon dark> mdi-calendar-check-outline </v-icon>
                    </v-btn></v-col
                  >
                </v-row>
                <v-icon dark v-if="request.success" class="mr-4" color="green">
                  mdi-check-all
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions, firebaseStorage } from "@/main";
import NavBar from "@/components/navbar";
import { EventBus } from "@/main";

export default {
  data: () => ({
    loading: true,
    working: false,
    requests: [],
  }),
  components: {
    NavBar,
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getVerifications();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getVerifications() {
      firebaseFirestore
        .collection("verification")
        .orderBy("timestamp")
        .limit(50)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            this.loading = false;
            this.emitLoadingState();
          } else {
            for (const doc of querySnapshot.docs) {
              const userDetails = await this.getUserDetails(doc.data().uid);
              this.requests.push({
                mobile: doc.data().mobile,
                uid: doc.data().uid,
                name: userDetails.name,
                profile: userDetails.profile,
                working: false,
                success: false,
                deleting: false,
              });
            }
            this.loading = false;
            this.emitLoadingState();
          }
        });
    },
    async getUserDetails(userID) {
      if (!userID) {
        return { name: "Error getting user details", profile: "" };
      }

      const docPath = firebaseFirestore.collection("users").doc(userID);
      try {
        const docSnap = await docPath.get();
        return { name: docSnap.data().name, profile: docSnap.data().profile };
      } catch (e) {
        console.log("Error getting user details ", e);
      }
    },
    verifyUser(index) {
      this.requests[index].working = true;
      this.working = true;

      var verify = firebaseFunctions.httpsCallable("verify");

      verify({
        userID: this.requests[index].uid,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.requests[index].success = true;
          setTimeout(() => this.requests.splice(index, 1), 3000);
        } else if (result.data.response == "Error") {
          console.log("Error - Report to Developer");
        }
      });
    },
    deleteRequest(index) {
      this.requests[index].deleting = true;
      this.working = true;

      firebaseFirestore
        .collection("verification")
        .doc(this.requests[index].mobile)
        .delete()
        .then(() => {
          this.working = false;
          this.requests.splice(index, 1);
        });
    },
  },
};
</script>

<style></style>
