import { render, staticRenderFns } from "./home.vue?vue&type=template&id=38fb0c33&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=38fb0c33&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fb0c33",
  null
  
)

/* custom blocks */
import block0 from "./home.vue?vue&type=custom&index=0&blockType=v-btn&class=ma-2&outlined=true&color=cyan%20darken-2&href=https%3A%2F%2Fapp.notify.lk%2Fapi%2Fv1%2Fstatus%3Fuser_id%3D15864%26api_key%3DjT7yJxHRRKaLiTQDGHTu&target=_blank"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VCol,VContainer,VDivider,VImg,VListItem,VRow})
