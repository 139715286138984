<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Drawer v-if="!$route.meta.requiresGuest" />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { firebaseAuth } from "@/main";
import Drawer from "@/components/drawer";

export default {
  name: "App",
  components: {
    Drawer,
  },
  data: () => ({
    //
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>