<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="center" class="mx-1 mt-10">
      <v-col cols="11" xs="11" sm="11" md="2" lg="3" xl="2">
        <v-card :elevation="1" rounded color="primaryCard">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="grey fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-2 grey--text">
              <h1>{{ userCount }}</h1>
              <h5 class="text-truncate text-uppercase text-subtitle-1">Total users</h5>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="11" xs="11" sm="11" md="2" lg="3" xl="2">
        <v-card :elevation="1" rounded color="primaryCard">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="blue-grey fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-2 blue-grey--text">
              <h1>{{ bookings }}</h1>
              <h5 class="text-truncate text-uppercase text-subtitle-1">Bookings today</h5>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="11" xs="11" sm="11" md="4" lg="6" xl="4">
        <v-card :elevation="1" rounded color="primaryCard">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="cyan darken-2 fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-2 cyan--text text--darken-2">
              <div :style="timeStyle">{{ this.time }}</div>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="closed" justify="center" align="center">
      <v-col xs="12" sm="12" md="4" lg="6" xl="4">
        <v-img src="@/assets/closed.png" contain height="300"> </v-img>
      </v-col>
    </v-row>
    <v-row v-if="!closed" justify="center" class="my-10">
      <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="8">
        <template v-for="(item, index) in appointments">
          <v-list-item :key="item.slot">
            <template>
              <v-card dark :color="item.color" width="100%">
                <v-row justify="space-between" class="ma-2">
                  <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="2">
                    <div style="font-size: 1.5em">
                      <strong>{{ item.slot }}</strong>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" lg="9" xl="10">
                    <v-row justify="start" class="mx-2">
                      <v-col cols="12" xs="12" sm="12" md="3" lg="4" xl="3">
                        <div style="text-caption">
                          Technician
                        </div>
                        <div style="text-h6">
                          {{ item.staff }}
                        </div>
                      </v-col><v-col cols="auto">
                        <v-row justify="start" align="center">
                          <v-col cols="auto">
                            <v-avatar v-if="item.profile != ''" size="40">
                              <img :src="item.profile" :alt="item.name" />
                            </v-avatar>
                          </v-col>
                          <v-col><div class="text-h6">
                            {{ item.name }}
                            </div>
                            <div class="text-caption">
                            {{ item.mobile }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-list-item>
          <v-divider v-if="index < appointments.length - 1" :key="index"></v-divider>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions } from "@/main";
import NavBar from "@/components/navbar";
import moment from "moment";
import { EventBus } from "@/main";

export default {
  data() {
    return {
      loading: true,
      working: false,
      userCount: 0,
      bookings: 0,
      smsBalance: null,
      timestampNow: new Date(),
      //Clock
      clockTimestamp: new Date(),
      interval: null,
      time: null,
      //
      closed: false,
      appointments: [],
      reloadCount: 0,
    };
  },
  components: {
    NavBar,
  },
  computed: {
    timeStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 2.8em";
        case "sm":
          return "font-size: 2.8em";
        case "md":
          return "font-size: 2.8em";
        case "lg":
          return "font-size: 3em";
        case "xl":
          return "font-size: 3em";
      }
      return null;
    },
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  async created() {
    this.loading = true;
    this.emitLoadingState();
    var getDate = firebaseFunctions.httpsCallable("getDate");

    await getDate({ format: "" }).then((result) => {
      this.timestampNow = new Date(result.data.formated);
      this.clockTimestamp = new Date(result.data.formated);

      this.interval = setInterval(() => {
        this.clockTimestamp.setSeconds(this.clockTimestamp.getSeconds() + 1);
        this.time = this.formatDate(this.clockTimestamp, "hh:mm:ss a");

        if (
          (this.clockTimestamp.getMinutes() == 30 ||
            this.clockTimestamp.getMinutes() == 31 ||
            this.clockTimestamp.getMinutes() == 32) &&
          this.clockTimestamp.getSeconds() == 1
        ) {
          this.reloadCheck();
        }
      }, 1000);

      this.getUserCount();
    });
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getUserCount() {
      firebaseFirestore
        .collection("analytics")
        .doc("users")
        .get()
        .then((returnedDoc) => {
          this.userCount = returnedDoc.data().users;
        })
        .then(() => {
          this.getBookingsCount();
        });
    },
    getBookingsCount() {
      firebaseFirestore
        .collection("appointments")
        .doc(this.formatDate(this.timestampNow, "DD-MMM-YYYY"))
        .get()
        .then((returnedDoc) => {
          this.bookings = returnedDoc.data().count;
          this.closed = returnedDoc.data().disabled;
        })
        .then(() => {
          if (this.closed == false) {
            this.reloadCheck();
          } else {
            this.loading = false;
            this.emitLoadingState();
          }
        });
    },
    reloadCheck() {
      this.reloadCount++;
      if (this.reloadCount > 800) {
        this.appointments = [];
        this.loading = true;
        this.emitLoadingState();
      } else {
        this.getAppointments();
      }
    },
    getAppointments() {
      this.appointments = [];
      this.loading = true;
      this.emitLoadingState();

      firebaseFirestore
        .collection("appointments")
        .doc(this.formatDate(this.timestampNow, "DD-MMM-YYYY"))
        .collection("slots")
        .orderBy("slot")
        .get()
        .then(async (querySnapshot) => {
          for (const doc of querySnapshot.docs) {
            if (this.clockTimestamp < doc.data().end.toDate()) {
              const userDetails = await this.getUserDetails(doc.data().userID);

              this.appointments.push({
                start: doc.data().start,
                slot: doc.data().slot,
                booked: doc.data().booked,
                disabled: doc.data().disabled,
                name: this.setName(doc, userDetails.name),
                mobile: userDetails.mobile,
                profile: userDetails.profile,
                staff: this.processStaff(doc.data().staff),
                color: this.setSlotColor(
                  doc.data().booked,
                  doc.data().disabled,
                  doc.data().start,
                  doc.data().end
                ),
              });
            }
          }
        })
        .then(() => {
          setTimeout(() => this.getBookingsCount(), 900000);
          this.loading = false;
          this.emitLoadingState();
        });
    },
    async getUserDetails(userID) {
      if (!userID) {
        return { name: "", mobile: "", profile: "" };
      }

      const docPath = firebaseFirestore.collection("users").doc(userID);
      try {
        const docSnap = await docPath.get();
        return { name: docSnap.data().name, mobile: docSnap.data().mobile, profile: docSnap.data().profile, };
      } catch (e) {
        console.log("Error getting user details ", e);
      }
    },
    setName(doc, name) {
      if (doc.data().disabled == true) {
        return "Slot Blocked " + doc.data().reason;
      } else if (doc.data().booked == false) {
        return "  -";
      } else {
        return name;
      }
    },
    setSlotColor(booked, disabled, start, end) {
      if (disabled == true) {
        return "red darken-4";
      } else if (booked == true) {
        if (this.clockTimestamp > start.toDate() && this.clockTimestamp < end.toDate()) {
          return "green darken-4";
        } else {
          return "light-blue darken-4";
        }
      } else {
        if (this.clockTimestamp > start.toDate() && this.clockTimestamp < end.toDate()) {
          return "green darken-4";
        } else {
          return "primaryCard";
        }
      }
    },
    formatDate(timeStamp, format) {
      var m = moment(timeStamp);
      var mFormatted = m.format(format);
      return mFormatted;
    },
    processStaff(name){
      if (name == undefined){
        return "ANY"
      } else {
        return name;
      }
    }
  },
};
</script>

<style scoped>
.dsfsdf {
  align-items: center;
}
</style>

*/

<v-btn class="ma-2" outlined color="cyan darken-2"
  href="https://app.notify.lk/api/v1/status?user_id=15864&api_key=jT7yJxHRRKaLiTQDGHTu" target="_blank">
      Check SMS Balance
    </v-btn>
