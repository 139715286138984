import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Login from '../views/login/login.vue'
import Forgot from '../views/login/forgot.vue'
import Home from '../views/home.vue'
import ViewTimeSlots from '../views/setup/viewTimeSlots'
import Appointments from '../views/appointments/appointments'
import EditAppointments from '../views/appointments/editAppointment'
import AppUsers from '../views/user/appUsers'
import ViewUser from '../views/user/viewUser'
import VerifyUser from '../views/verification/verify'
import Banner from '../views/setup/banner'
import SMS from '../views/sms/history'

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: Forgot,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
            title: "Dashboard",
        },
    },
    {
        path: '/timeslots',
        name: 'timeslots',
        component: ViewTimeSlots,
        meta: {
            requiresAuth: true,
            title: "Time Slots"
        }
    },
    {
        path: '/appointments',
        name: 'appointments',
        component: Appointments,
        meta: {
            requiresAuth: true,
            title: "Appointments"
        }
    },
    {
        path: '/appointments/:date/:slot',
        name: 'EditAppointment',
        component: EditAppointments,
        meta: {
            requiresAuth: true,
            title: "Edit Appointment"
        }
    },
    {
        path: '/appUsers',
        name: 'appUsers',
        component: AppUsers,
        meta: {
            requiresAuth: true,
            title: "App Users"
        }
    },
    {
        path: '/viewUser/:userID',
        name: 'viewUser',
        component: ViewUser,
        meta: {
            requiresAuth: true,
            title: "View User"
        }
    },
    {
        path: '/verifyUser',
        name: 'verifyUser',
        component: VerifyUser,
        meta: {
            requiresAuth: true,
            title: "Verify User"
        }
    },
    {
        path: '/banner',
        name: 'banner',
        component: Banner,
        meta: {
            requiresAuth: true,
            title: "Notice Banners"
        }
    },
    {
        path: '/sms',
        name: 'sms',
        component: SMS,
        meta: {
            requiresAuth: true,
            title: "SMS sent"
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Nav Guard
router.beforeEach((to, from, next) => {
    // Check for requiresAuth guard
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if NO logged user
        if (!firebase.auth().currentUser) {
            // Go to login
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Proceed to route
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        // Check if NO logged user
        if (firebase.auth().currentUser) {
            // Go to login
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Proceed to route
            next();
        }
    } else {
        // Proceed to route
        next();
    }
});

export default router