<template>
  <v-container fluid>
    <NavBar />
    <v-row justify="end">
      <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
        <v-text-field
                  filled
                  rounded
                  required
                  dark
                  v-model="number"
                  type="number"
                  color="primary"
                  label="Number"
                  :disabled="loading"
                />
        <v-btn color="primaryButton" class="white--text mt-6" @click="setDate" :loading="loading">
          <v-icon left> mdi-plus </v-icon>
          Add Time Slot
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="5">
        <template v-for="slot in slots">
          <v-list-item :key="slot.id">
            <template>
              <v-card width="90%" :elevation="1" color="primaryCard" class="rounded-card">
                <div class="timeText ml-4 my-2">{{ slot.start }} - {{ slot.end }}</div>
                <v-divider dark></v-divider>
                <div class="subtext mr-4 my-2">{{ slot.text }}</div>
              </v-card>
            </template>
          </v-list-item>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" :persistent="working" max-width="600px">
      <v-card color="secondaryCard">
        <v-card-title>
          <span class="text-h5">Add Slot</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col> </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { firebaseFirestore, firebaseFunctions } from "@/main";
import NavBar from "@/components/navbar";
import { EventBus } from "@/main";

export default {
  components: {
    NavBar,
  },
  data: () => ({
    loading: true,
    admin: false,
    working: false,
    dialog: false,
    slots: [],
    number: 0,
    hours: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "01",
      "01",
      "01",
      "01",
      "01",
      "01",
      "01",
      "01",
      "01",
    ],
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();

    if (localStorage.role == "admin") {
      this.admin = true;
    } else {
      this.admin = false;
    }
    this.getTimeSlots();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getTimeSlots() {
      firebaseFirestore
        .collection("timeslots")
        .orderBy("order")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              start: doc.data().start,
              end: doc.data().end,
              id: doc.data().id,
              text: doc.data().text,
              order: doc.data().order,
            };
            this.slots.push(data);
          });

          this.loading = false;
          this.emitLoadingState();
        });
    },
    setDate() {
      this.loading = true;
      this.emitLoadingState();
      var tempDate = firebaseFunctions.httpsCallable("tempDate");
      tempDate({ number: parseInt(this.number)}).then((result) => {
        if (result.data.response == "Success") {
          this.loading = false;
          this.emitLoadingState();
        } else {
          console.log(result.data.response);
        }
      });
    },
  },
};
</script>

<style scoped>
.timeText {
  color: white;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}
.subtext {
  color: white;
  font-size: 1em;
  font-weight: 400;
  text-align: end;
}
.rounded-card {
  border-radius: 15px;
}
</style>
