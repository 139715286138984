<template>
  <v-container class="loginBack" fill-height fluid>
    <v-row justify="center">
      <v-col cols="11" xs="11" sm="11" md="6" lg="4" xl="3">
        <v-card>
          <v-row justify="center">
            <v-col cols="10">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="welcomeTxt mt-6">Forgot your password?</div>
                <div class="welcomeTag mb-6">Please enter the email you use to sign in</div>
                <v-text-field
                  filled
                  rounded
                  required
                  v-model="email"
                  type="email"
                  color="primary"
                  label="Email"
                  append-icon="mdi-email"
                  :rules="emailRules"
                  :disabled="working"
                />
                <div class="errorTxt">{{ errorText }}</div>
                <v-btn
                  x-large
                  block
                  rounded
                  class="mt-4"
                  v-on:click="reset"
                  :loading="working"
                  color="primary"
                  >Request password reset</v-btn
                >
                <v-btn
                  plain
                  block
                  color="primary"
                  class="mt-3 mb-5"
                  href="/login"
                  >Back to Login</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
        <div class="my-3">o</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      working: false,
      email: "",
      emailRules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 40 || "Max 40 characters",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      errorText: "",
    };
  },
  methods:{
      reset(){

      }
  }
};
</script>

<style scoped>
.loginBack {
  width: 100vw;
  height: 100vh;
  background-color: rgb(46, 46, 46, 0.9); /* Tint color */
  background-blend-mode: multiply;
  background-image: url("~@/assets/rhcBackground.jpg");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.welcomeTxt {
  font-size: 1.3em;
  font-weight: 500;
  text-align: center;
}
.welcomeTag {
  font-size: 1em;
  font-weight: 400;
  text-align: center;
}
.mobileNumber {
  font-size: 1.4em;
}
.errorTxt {
  text-align: center;
  font-size: 1em;
  color: #ff1100;
  font-weight: 500;
}
.otpTxt {
  font-size: 1.4em;
}
</style>
